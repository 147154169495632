<i18n>
{
  "de": {
    "pageTitle": "Portfolio erstellen",
    "portfolioNameLabel": "Name",
    "submitButtonLabel": "Erstellen",
    "errorHeader": "Beim Speichern ist ein Fehler aufgetreten"
  }
}
</i18n>

<template>
  <MainLayout class="admin-create-portfolio-page">
    <template #default>
      <h1>{{ $t('pageTitle') }}</h1>
      <div v-if="error" class="error-message">
        <h2>{{ $t('errorHeader') }}</h2>
        <p>
          <b>{{ error }}</b>
        </p>
      </div>
      <form>
        <label for="portfolio-name-field">{{ $t('portfolioNameLabel') }}</label>
        <input id="portfolio-name-field" v-model="name" v-focus type="text" />
        <ButtonWrapper>
          <button class="button" :disabled="!submitButtonEnabled" @click.prevent="onSubmit">
            {{ $t('submitButtonLabel') }}
          </button>
        </ButtonWrapper>
      </form>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

export default {
  name: 'adminAddPortfolio',

  components: {
    ButtonWrapper,
    MainLayout,
  },

  data() {
    return {
      name: null,
      error: null,
    }
  },

  computed: {
    submitButtonEnabled() {
      return Boolean(this.name)
    },
  },

  methods: {
    ...mapActions({
      addPortfolio: 'portfolio/addPortfolio',
    }),

    async onSubmit() {
      try {
        this.error = null
        const portfolio = await this.addPortfolio({
          name: this.name,
        })
        this.$router.push({ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } })
      } catch (error) {
        this.error = error.message
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.admin-create-portfolio-page form {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
}

.admin-create-portfolio-page form button {
  grid-column-end: -1;
  justify-self: start;
}

.admin-create-portfolio-page .error-message {
  padding: var(--box-padding);
  border: var(--box-error-border);
  max-width: 800px;
}

.admin-create-portfolio-page .error-message h2 {
  color: var(--error-color);
}
</style>
